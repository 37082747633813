<template>
  <div class="up-add-edit-test">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <el-form>
      <el-form-item label="Название теста">
        <el-input
          v-model="testData.name"
          placeholder="Введите название теста"
        />
      </el-form-item>
      <el-form-item label="Проходной балл. % верных ответов">
        <el-input v-model="minScore" placeholder="Введите проходной балл" />
      </el-form-item>
      <el-form-item
        v-for="(q, i) in questions"
        :key="i"
        class="up-add-edit-test__question up-question"
      >
        <div v-if="questions.length > 1" class="up-question__move-buttons">
          <i
            v-if="i > 0"
            class="el-icon-top"
            aria-label="Перемещение вопроса"
            role="button"
            @click="changeQuestions(i, i - 1)"
          />
          <i
            v-if="i != questions.length - 1"
            class="el-icon-bottom"
            aria-label="Перемещение вопроса"
            role="button"
            @click="changeQuestions(i, i + 1)"
          />
        </div>
        <div class="up-question__body">
          <div class="up-question__header">
            <el-input v-model="q.description" placeholder="Введите вопрос" />
            <i
              class="up-question__btn-remove el-icon-close"
              role="button"
              aria-label="Удалить вопрос"
              @click="removeQuestion(i)"
            />
          </div>
          <div
            v-for="(a, j) in q.answers"
            :key="`answer${j}`"
            class="up-question__answer-wrapper"
          >
            <el-radio v-model="q.correct" :label="j" />
            <el-input v-model="a.text" placeholder="Введите ответ" />
            <i
              class="up-question__btn-remove el-icon-close"
              role="button"
              aria-label="Удалить вопрос"
              @click="removeAnswer(i, j)"
            />
          </div>
          <el-button
            class="up-question__add-answer-btn"
            plain
            @click="addAnswer($event, i)"
          >
            Добавить вариант ответа
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="addQuestion">Добавить вопрос</el-button>
    <footer class="up-add-edit-test__footer">
      <router-link
        class="el-button el-button--danger"
        :to="`/methodist/courses/${$route.params.id}`"
      >
        Отменить
      </router-link>
      <el-button
        type="success"
        @click="saveHandler"
        :disabled="btnSaveDisabled"
      >
        Сохранить
      </el-button>
    </footer>
  </div>
</template>

<script>
import testMixin from "@/mixins/methodist/add-edit-test.mixin";

export default {
  name: "AddNewTest",
  mixins: [testMixin],
  methods: {
    async saveHandler() {
      const questions = this.formattedQuestions();
      const cid = this.$route.params.id;

      try {
        await this.$axios.post(`/constructor/course/${cid}/lesson`, {
          type: "test",
          ...this.testData,
          questions,
        });

        this.$message({
          message: "Поздравляем! Тест успешно добавлен.",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${cid}`);
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Добавление нового теста");
  },
  mounted() {
    this.breadcrumbs.push({ text: "Добавление нового теста" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/show/new-edit-test.scss";
</style>